import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../../components/Layout'
import ServiceFooter from '../../../components/ServiceFooter'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Service = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`駐車場利用者向けサービス「QT-net サービス」 | ${title}`}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>駐車場利用者向けサービス「QT-net サービス」</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li> 駐車場利用者向けサービス「QT-net サービス」</li>
        </div>
      </div>

      <div className="main-wrapper qtservice">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net サービス</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              「QT-net
              サービス」は、ネットワークシステム「QT-net」を活用した、個人向け、法人向けの便利な駐車場サービスです。
            </h4>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-logo.webp"
              />
              <img
                className="qtservice-logo"
                src="/assets/images/qtnet-service/qtnet-logo.png"
                width="242"
                height="100"
                alt="QT-netロゴ"
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>お知らせ</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p>
              2024年10月31日をもちまして、フェリカ携帯サービスのご利用は終了となりました。
            </p>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>目次</h3>
          </div>
        </div>

        <div className="row toc">
          <div className="container">
            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice#top`}>
                  QT-net 個人会員サービス
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink to={`/service/qtnetservice#tool`}>
                      QT-net駐車場ツール
                    </AnchorLink>
                    <ul>
                      <li>
                        <AnchorLink to={`/service/qtnetservice#search`}>
                          ① 駐車場をさがす
                        </AnchorLink>
                        <ul>
                          <li>
                            <AnchorLink to={`/service/qtnetservice#navi`}>
                              「駐車場検索」
                            </AnchorLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <AnchorLink to={`/service/qtnetservice#booking`}>
                          ② 駐車場を予約する
                        </AnchorLink>
                        <ul>
                          <li>
                            <AnchorLink to={`/service/qtnetservice#coinpa`}>
                              「コインパ予約」
                            </AnchorLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <AnchorLink to={`/service/qtnetservice#payment`}>
                          ③ スマホで精算する
                        </AnchorLink>
                        <ul>
                          <li>
                            <AnchorLink to={`/service/qtnetservice#smartpay`}>
                              「Smart Pay」
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink to={`/service/qtnetservice#numberpay`}>
                              「ナンバーペイ」
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink to={`/service/qtnetservice#card`}>
                              「QT-net モバイルカード」
                            </AnchorLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <AnchorLink to={`/service/qtnetservice#member`}>
                          ④ 会員情報の確認や編集
                        </AnchorLink>
                        <ul>
                          <li>
                            <AnchorLink
                              to={`/service/qtnetservice#member-page`}
                            >
                              「会員情報ページ」
                            </AnchorLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <AnchorLink to={`/service/qtnetservice#qt-card`}>
                      QT-net カード
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to={`/service/qtnetservice#qt-point`}>
                      QT-net ポイント
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice#qt-net-service`}>
                  QT-net 法人会員サービス
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice#qt-net-service-item`}
                    >
                      QT-net 法人会員サービス
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div id="top" className="row">
          <div className="h-wrapper">
            <h3>QT-net 個人会員サービス </h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h4>
              駐車場の利用体験が向上する、
              <br />
              便利な駐車場アプリやリアルカード。
              <br />
              料金精算ができたり、貯めて交換できるお得なポイントサービスがございます。
            </h4>
          </div>
        </div>

        <div id="tool" className="row">
          <div className="h-wrapper">
            <h3>QT-net 駐車場ツール</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p className="sm12">
              駐車場の、検索/予約/精算ができる便利な駐車場アプリ。駐車場利用に応じてポイントが貯まり、お得に駐車場をご利用いただけます。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-service-1.webp"
              />
              <img
                className="qtservice-01 aligncenter"
                src="/assets/images/qtnet-service/qtnet-service-1.png"
                width="590"
                height="590"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="search" className="row">
          <div className="h-wrapper">
            <h3>① 駐車場をさがす</h3>
          </div>
        </div>

        <div id="navi" className="row">
          <div className="container">
            <h5>「駐車場検索」</h5>
            <h6>〜停めたい駐車場を探そう！</h6>
            <p className="sm12">
              駐車場をエリア・空き状況・料金体系・駐車場タイプで検索することができます。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-service-2.webp"
              />
              <img
                className="qtservice-02 aligncenter"
                src="/assets/images/qtnet-service/qtnet-service-2.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="booking" className="row">
          <div className="h-wrapper">
            <h3>② 駐車場を予約する</h3>
          </div>
        </div>

        <div id="coinpa" className="row">
          <div className="container">
            <h5>「コインパ予約」</h5>
            <h6>
              〜駐車場を予約しよう！
              <br className="sp-only" />
              精算は、クレジットカードで自動精算！
            </h6>
            <p className="sm12">
              一部対応駐車場で予約が可能です。予約は利用時間の2時間前から。出庫時には、登録したクレジットカードで自動で精算されるため、手間なく出庫できます。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-service-3.webp"
              />
              <img
                className="qtservice-03 aligncenter"
                src="/assets/images/qtnet-service/qtnet-service-3.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <div className="btn-gr">
              <Link to={`/service/qtnetservice/booking`}>
                <p className="title">詳しく見る</p>
              </Link>
            </div>
          </div>
        </div>

        <div id="payment" className="row">
          <div className="h-wrapper">
            <h3>③ スマホで精算する</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5 id="smartpay">「Smart Pay」</h5>
            <h6>〜精算機に行かずに、アプリで精算しよう！</h6>
            <p className="sm12">
              アプリで、駐車場と車室番号を指定して精算すると、登録したクレジットカードから精算が可能です。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-service-4.webp"
              />
              <img
                className="qtservice-04 aligncenter"
                src="/assets/images/qtnet-service/qtnet-service-4.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5 id="numberpay">「ナンバーペイ」</h5>
            <h6>〜ナンバー認証で、自動精算！</h6>
            <p className="sm12">
              一部対応駐車場にて、ナンバー認証で自動精算が可能です。
              ナンバーのご登録は、対応駐車場にて一度ナンバーを認証させるだけ。
              次回利用時から、入庫確認のワンタップのみで精算ができます。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-service-5.webp"
              />
              <img
                className="qtservice-05 aligncenter"
                src="/assets/images/qtnet-service/qtnet-service-5.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <div className="btn-gr">
              <Link to={`/service/qtnetservice/numberpay`}>
                <p className="title">詳しく見る</p>
              </Link>
            </div>

            <h5 id="card">「QT-net モバイルカード」</h5>
            <h6>〜QT-net のバーコードで精算しよう！</h6>
            <p className="sm12">
              登録したクレジットカードでのバーコード決済ができたり、現金精算の場合は、バーコードをかざすことでQT-net
              ポイントを貯められる、モバイル会員カードです。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-service-6.webp"
              />
              <img
                className="qtservice-06 aligncenter"
                src="/assets/images/qtnet-service/qtnet-service-6.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="member" className="row">
          <div className="h-wrapper">
            <h3>④ 会員情報の確認や編集</h3>
          </div>
        </div>

        <div id="member-page" className="row">
          <div className="container">
            <h5>「会員情報ページ」</h5>
            <h6>
              〜会員情報、ポイントを管理しよう！
              <br />
              領収書発行もこちらから。
            </h6>
            <p>
              会員情報やクレジットカード情報の確認/変更、ポイントの確認/交換、領収書の発行ができます。
            </p>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>アプリのダウンロードはこちら</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <div className="green-frame">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja&gl=US&pli=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <div id="qt-card" className="row">
          <div className="h-wrapper">
            <h3>2.QT-net カード</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p className="sm12">
              登録したクレジットカードでの精算が可能リアル会員カードです。現金精算の場合は、精算後にバーコードをかざすことでQT-net
              ポイントを貯められます。QT-net
              カードのアカウント情報で、アプリへのログインも可能です。
            </p>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qtnet-card.webp"
              />
              <img
                className="qt-card aligncenter"
                src="/assets/images/qtnet-service/qtnet-card.png"
                width="590"
                height="350"
                alt=""
              />
            </picture>

            <div className="box qt aligncenter">
              <a
                href="https://qt-net.itcbb.net/signup?qtnet_card=true"
                target="_blank"
                rel="noreferrer"
              >
                <p className="title">QT-net カード新規申し込み</p>
                <p className="detail">
                  ご不明な点がございましたら、
                  <br />
                  お気軽にお問い合わせください。
                </p>
                <p className="green">info@i-tech-corp.co.jp</p>
                <div className="link-box">新規申し込みはこちら</div>
              </a>
            </div>
          </div>
        </div>

        <div id="qt-point" className="row">
          <div className="h-wrapper">
            <h3>3.QT-net ポイント</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p>
              駐車場利用料に応じてポイントが貯まります。
              <br />
              貯まったポイントはギフト券または多数のサービスに交換できるGポイントに交換することができます。
            </p>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/point.webp"
              />
              <img
                className="point aligncenter"
                src="/assets/images/qtnet-service/point.png"
                width="590"
                height="330"
                alt=""
              />
            </picture>

            <p className="note gray">
              *本プロモーション・キャンペーンは株式会社アイテックによる提供です。
              本プロモーション・キャンペーンについてのお問い合わせはAmazonではお受けしておりません。QT-net【info@i-tech-corp.co.jp】までお願いいたします。
              <br />
              * Amazon、Amazon.co.jpおよびそのロゴはAmazon.com, Inc.
              またはその関連会社の商標です。
              <br />
              *「nanaco」は株式会社セブン・カードサービスの登録商標です。
              <br />
              *「ＷＡＯＮ」はイオン株式会社の登録商標です。
            </p>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>QT-net ポイントを貯めるにはこちらから！</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <div className="green-frame">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja&gl=US&pli=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>

            <div className="box qt">
              <a
                href="https://qt-net.itcbb.net/signup?qtnet_card=true"
                target="_blank"
                rel="noreferrer"
              >
                <p className="title">QT-net カード新規申し込み</p>
                <p className="detail">
                  ご不明な点がございましたら、
                  <br />
                  お気軽にお問い合わせください。
                </p>
                <p className="green">info@i-tech-corp.co.jp</p>
                <div className="link-box">新規申し込みはこちら</div>
              </a>
            </div>
          </div>
        </div>

        <div id="qt-net-service" className="row">
          <div className="h-wrapper">
            <h2>QT-net 法人会員サービス</h2>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h4>
              法人様の駐車場利用が便利になります。利用手数料・カード発行手数料・年会費が全て無料で、ご利用いただきます。
            </h4>
          </div>
        </div>

        <div id="qt-net-service-item" className="row">
          <div className="h-wrapper">
            <h3>QT-net 法人会員サービス</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p className="sm12">
              QT-net 法人会員に登録することで、QT-net
              法人カードを利用でき、QT-net
              加盟駐車場での口座振替が可能になります。
            </p>

            <h5>「QT-net 法人カード」</h5>
            <h6>
              〜駐車場料金を、「QT-net
              法人カード」で精算することで、まとめて口座振替にすることができるリアルカードです。
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/qt-corp-card.webp"
              />
              <img
                className="qt-corp-card aligncenter"
                src="/assets/images/qtnet-service/qt-corp-card.png"
                width="590"
                height="351"
                alt=""
              />
            </picture>

            <h5>メリット①月単位での口座振替</h5>
            <h6>〜口座振替に一本化することで、経費精算の手間を削減。</h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/service-image-1.webp"
              />
              <img
                className="service-image-01 aligncenter"
                src="/assets/images/qtnet-service/service-image-1.png"
                width="590"
                height="333"
                alt=""
              />
            </picture>

            <h5>メリット②利用状況の可視化</h5>
            <h6>
              〜Webの管理画面で、個人ごとの利用状況の詳細を把握することが可能です。
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/service-image-2.webp"
              />
              <img
                className="service-image-02 aligncenter"
                src="/assets/images/qtnet-service/service-image-2.png"
                width="590"
                height="333"
                alt=""
              />
            </picture>

            <h5>メリット③暗証番号による不正利用防止</h5>
            <h6>
              〜管理者がWebの管理画面から、カードごとの暗証番号を設定できるので、紛失時の不正利用防止が可能です。
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/service-image-3.webp"
              />
              <img
                className="service-image-03 aligncenter"
                src="/assets/images/qtnet-service/service-image-3.png"
                width="590"
                height="333"
                alt=""
              />
            </picture>

            <div className="box qt aligncenter">
              <a
                href="https://www.itcbb.net/gns/corp.html"
                target="_blank"
                rel="noreferrer"
              >
                <p className="title">QT-net 法人会員 新規申し込み</p>
                <p className="detail">
                  ご不明な点がございましたら、
                  <br />
                  お気軽にお問い合わせください。
                </p>
                <p className="green">info@i-tech-corp.co.jp</p>
                <div className="link-box">新規申し込みはこちら</div>
              </a>
            </div>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Service
