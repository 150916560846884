import React from 'react'
import { Link } from 'gatsby'

const ServiceFooter = () => {
  return (
    <div className="row bottom">
      <div className="container contact">
        <div className="box left">
          <Link to={`/whitepaper/`}>
            <p className="title">製品カタログ ダウンロード</p>
            <p className="detail">
              製品・ソリューション、弊社の事例など、
              <br />
              お気軽にお問い合わせください。
            </p>
            <div className="link-box">ダウンロードページはこちら</div>
            <div className="shadow sp-only"></div>
          </Link>
        </div>

        <div className="box right">
          <Link to={`/contact/`}>
            <p className="title">お問い合わせ</p>
            <p className="detail">
              お電話、またはお問い合わせフォームより
              <br />
              お問い合わせや、ご注文が可能です。
            </p>
            <div className="link-box">お問い合わせフォームはこちら</div>
            <div className="shadow sp-only"></div>
          </Link>
        </div>
        <div className="shadow pc-only"></div>
      </div>
    </div>
  )
}

export default ServiceFooter
